"use client";

import React, { useState } from "react";
import { AscendiLogo } from "./logo";
import nav from "./nav.module.css";

import { Link } from "@nextui-org/link";
import {
	Navbar as Nav, 
	NavbarBrand, 
	NavbarContent, 
	NavbarItem,
	NavbarMenuToggle,
	NavbarMenu,
	NavbarMenuItem
} from "@nextui-org/navbar";

export default function Navbar() {
    const [ isMenuOpen, setMenuState ] = useState(false);
    const menuItems = [
        { name:"Hjem",          href:"/" },
        { name:"Tjenester",     href:"/#tjenester" },
        { name:"Om Oss",        href:"/#om" },
        { name:"Blogg",         href:"/blog/" },
        { name:"Kontakt Oss",   href:"/kontakt/", color:"warning" },
    ];

    return (
        <Nav 
            className={`${nav.bar} ${isMenuOpen ? nav.active : ""}`} 
            isBlurred={false}
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setMenuState}
        >
            <NavbarBrand
                href="/"
                color="foreground"
                as={Link}
            >
                <AscendiLogo />
            </NavbarBrand>
            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                {menuItems.map((item, i) => (
                    <NavbarItem key={i}>
                        <Link href={item.href} color="foreground"> 
                            {item.name}
                        </Link>
                    </NavbarItem>
                ))}
            </NavbarContent>
            <NavbarContent className="sm:hidden" justify="end">
                <li className='h-full'>
                    <NavbarMenuToggle className='px-8' aria-label={isMenuOpen ? "Lukk navigeringsmeny" : "Åpne navigeringsmeny"} />
                </li>
            </NavbarContent>
            <NavbarMenu className={`${nav.dropdown} ${isMenuOpen ? nav.active : ""}`}>
                {menuItems.map((item, i) => (
                    <NavbarMenuItem key={i}>
                        <Link
                            className="w-full text-lg"
                            href={item.href}
                            color={item.color || "foreground"}
                            onClick={() => setMenuState(false)}
                        >
                            {item.name}
                        </Link>
                    </NavbarMenuItem>
                ))}
                <NavbarMenuItem className="flex-grow"/>
                <NavbarMenuItem className="mb-24">
                    <div className="col-span-2 flex flex-col justify-center text-center">
                        <div className="pb-2">
                            <a className="mx-auto text-lg" href="tel:+4798082082">Ta kontakt: <span className="font-semibold">98 082 082</span></a>
                        </div>
                        <div className="pb-2">
                            <a className="mx-auto text-lg" href="tel:+4722330100">Døgnåpen vakt telefon: <span className="font-semibold">223 30 100</span></a>
                        </div>
                    </div>
                </NavbarMenuItem>
            </NavbarMenu>
        </Nav>
    )
}